import * as React from "react";
import { clsx } from "clsx";
import { DayPicker } from "components/DayPicker";
import Select from "components/Select";
import { RefillZoneDropDown } from "containers/maintenance/RefillZoneDropDown";
import { SelectedZoneChips } from "containers/maintenance/SelectedZoneChips";
import {
  SelectedZonesProvider,
  useSelectedZones,
} from "containers/maintenance/SelectedZoneProvider";
import { ServiceZoneDropDown } from "containers/maintenance/ServiceZoneDropDown";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toasti18n } from "utils/toast";

import { ErrorPlaceHolder } from "./ErrorPlaceHolder";
import { FrequentErrorAccordion } from "./FrequentErrorAccordion";
import { LastUpdatedIndicator } from "./LastUpdatedIndicator";
import { LoadingPlaceHolder } from "./LoadingPlaceHolder";
import { MachineCard } from "./MachineCard";
import { NoDataPlaceHolder } from "./NoDataPlaceHolder";
import { useRealTimeQuery } from "./useRealTimeQuery";

export function RtFail({ className }: { className: string }) {
  return (
    <SelectedZonesProvider>
      <RtFailContents className={className} />
    </SelectedZonesProvider>
  );
}

type RecurringCount = "ALL" | "2DAYS" | "3DAYS" | "4DAYS+";
function mapRecurrentCountToMinMax(recurringCount: RecurringCount) {
  switch (recurringCount) {
    case "ALL":
      return { min: null, max: null };
    case "2DAYS":
      return { min: 2, max: 2 };
    case "3DAYS":
      return { min: 3, max: 3 };
    case "4DAYS+":
      return { min: 4, max: null };
  }
}

export function RtFailContents({ className }: { className: string }) {
  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  const { serviceZoneIds, refillZoneIds } = useSelectedZones();

  const [date, setDate] = React.useState<Date>(new Date());
  const dateString = moment(date).format("YYYY-MM-DD");
  const [recurringCount, setRecurringCount] =
    React.useState<RecurringCount>("ALL");

  const realTimeFailResult = useRealTimeQuery(
    {
      date: dateString,
      refillZoneIds: refillZoneIds.length === 0 ? null : refillZoneIds,
      serviceZoneIds: serviceZoneIds.length === 0 ? null : serviceZoneIds,
      ...mapRecurrentCountToMinMax(recurringCount),
    },
    {
      onError: (error: Error) => {
        toasti18n.error(error);
      },
    }
  );

  return (
    <>
      <div className="overflow-auto hide-scroll-bar -mx-4 px-4 md:-mx-10 md:px-10 flex shrink-0 animate-fade-in-move-up">
        <div className="grow">
          <div className="flex justify-between gap-2.5 mt-2 md:mt-4 h-14">
            <div className="grid [grid-template-columns:210px_210px_210px] gap-2 md:gap-4 grow">
              <ServiceZoneDropDown />
              <RefillZoneDropDown />

              <div className="bg-white scale-y-[1.04] rounded">
                <Select
                  label={t("recurringCount.selectLabel")}
                  id="recurring_count"
                  labelSx={{
                    fontWeight: 500,
                  }}
                  options={[
                    {
                      value: "ALL",
                      label: t("recurringCount.all"),
                    },
                    {
                      value: "2DAYS",
                      label: t("recurringCount.2days"),
                    },
                    {
                      value: "3DAYS",
                      label: t("recurringCount.3days"),
                    },
                    {
                      value: "4DAYS+",
                      label: t("recurringCount.4days+"),
                    },
                  ]}
                  onChange={(e) => setRecurringCount(e.target.value)}
                  value={recurringCount}
                />
              </div>
            </div>

            <div className="items-center gap-6 shrink-0 flex">
              <LastUpdatedIndicator
                realTimeFailResult={realTimeFailResult}
                date={date}
              />
              <div className="h-full">
                <DayPicker
                  selected={date}
                  onSelect={setDate}
                  ButtonComponent={RtFailDayPickerButton}
                />
              </div>
            </div>
          </div>

          <div className="mt-1 md:mt-2">
            <SelectedZoneChips />
          </div>
        </div>
      </div>
      <div className="grow flex flex-col min-h-0 isolate">
        <div className={clsx("w-full h-full flex flex-col", className)}>
          <div className="flex flex-grow gap-4 mt-1 md:mt-2 min-h-0 overflow-auto snap-x snap-mandatory">
            <div className="shrink-0 md:shrink w-[calc(100%-46px)] md:w-auto md:flex-grow md:basis-0 md:min-w-0 flex flex-col snap-start snap-always">
              <FrequentErrorAccordion
                title={t("swHw")}
                board={realTimeFailResult.data?.softwareHardwareFailBoard}
                isLoading={realTimeFailResult.isLoading}
              />
              <div
                className="min-h-0 overflow-auto grow flex flex-col gap-4 pt-4 hide-scroll-bar"
                key={dateString}
              >
                {realTimeFailResult.data?.softwareHardwareFailBoard.machines
                  .length === 0 ? (
                  <NoDataPlaceHolder />
                ) : null}

                {realTimeFailResult.isError && !realTimeFailResult.data ? (
                  <ErrorPlaceHolder />
                ) : null}

                {realTimeFailResult.isLoading ? <LoadingPlaceHolder /> : null}
                {realTimeFailResult.data?.softwareHardwareFailBoard.machines.map(
                  (machine) => (
                    <MachineCard
                      key={machine.machineId}
                      machine={machine}
                      columnType="software-hardware-fail"
                    />
                  )
                )}
              </div>
            </div>
            <div className="shrink-0 md:shrink w-[calc(100%-46px)] md:w-auto md:flex-grow md:basis-0 md:min-w-0 flex flex-col snap-start snap-always hide-scroll-bar">
              <FrequentErrorAccordion
                title={t("inventory")}
                board={realTimeFailResult.data?.inventoryFailBoard}
                isLoading={realTimeFailResult.isLoading}
              />
              <div
                className="min-h-0 overflow-auto grow flex flex-col gap-4 pt-4 hide-scroll-bar"
                key={dateString}
              >
                {realTimeFailResult.isLoading ? <LoadingPlaceHolder /> : null}

                {realTimeFailResult.isError && !realTimeFailResult.data ? (
                  <ErrorPlaceHolder />
                ) : null}

                {realTimeFailResult.data?.inventoryFailBoard.machines.length ===
                0 ? (
                  <NoDataPlaceHolder />
                ) : null}

                {realTimeFailResult.data?.inventoryFailBoard.machines.map(
                  (machine) => (
                    <MachineCard
                      key={machine.machineId}
                      machine={machine}
                      columnType="inventory"
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RtFailDayPickerButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & { isOpen: boolean }
) {
  return (
    <button
      {...props}
      className={clsx(
        "font-kanit w-56 py-3 flex px-4 text-subtitle1 items-center leading-6 text-on-surface-medium rounded-[4px] bg-transparent border  hover:border-black cursor-pointer bg-white h-full",
        props.isOpen ? "border-black" : "border-outline"
      )}
    />
  );
}
