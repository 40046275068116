import { useEffect } from "react";
import { useAuthStore } from "userandauth/useAuthStore";
import { updateDefaultTimezone } from "utils/date";

import { useGetUser, useGetUserOptions } from "./useGetUser";

function useGetCurrentUser(options?: useGetUserOptions) {
  const userId = useAuthStore((state) => state.userId);
  const { data, ...otherProps } = useGetUser(userId, {
    ...options
  });

  useEffect(() => {
    if(data?.timezone) {
      updateDefaultTimezone(data?.timezone);
    }
  }, [data])
  return { ...otherProps, data }
}

export { useGetCurrentUser };
