import { AxiosRequestConfig } from "axios";
import { httpClient } from "common/services/transportService";

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: AxiosRequestConfig["headers"],
  axiosOptions?: Omit<AxiosRequestConfig, "headers">
): (() => Promise<TData>) => {
  return async () => {
    const res = await httpClient.post<{
      data: TData;
      errors?: Array<{ message: string }>;
    }>(
      "/graphql",
      { query, variables },
      {
        ...axiosOptions,
        headers: {
          ...(options ?? {})
        },
      }
    );
    if ("errors" in res.data) {
      throw new Error(res.data.errors[0].message);
    }

    return res.data.data;
  };
};
