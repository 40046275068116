import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import i18n from "common/i18n";
import { IPatchUser } from "userandauth/interfacesUser";
import { patchUser } from "userandauth/servicesUser";
import { toasti18n } from "utils/toast";

import { userKeys } from "./userKeys";

function usePatchUserDetails() {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError, IUsePatchUser>({
    mutationFn: ({ newUserData, userId }) => patchUser(newUserData, userId),
    onSuccess: (_, { userId }) => {
      toasti18n.success(i18n.t("toast_user_patch_success"));
      queryClient.invalidateQueries({
        queryKey: userKeys.user(userId),
      });
      queryClient.invalidateQueries({
        queryKey: userKeys.all(),
      });
      queryClient.invalidateQueries();
    },
  });
}

export { usePatchUserDetails };

interface IUsePatchUser {
  userId: string;
  newUserData: IPatchUser;
}
