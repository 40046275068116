import { IUser } from "userandauth/interfacesUser";

import analytics from "./analytics";

export function createUserProperties(userInfo: IUser) {
  return {
    userId: userInfo.userId,
    properties: {
      email: userInfo.email,
      mode_canViewAllMachines: userInfo.canViewAllMachines,
      organization_name: userInfo.orgName,
      organization_ID: userInfo.orgId,
      permission_canImpersonate: userInfo.canImpersonate,
      permission_canManageLocations: userInfo.canManageLocations,
      permission_canToggleViewAllMachines: userInfo.canToggleViewAllMachines,
      role: userInfo.role,
      locale_id: userInfo.locale,
    },
  };
};


export async function userTracking (userInfo: IUser) {
  analytics.identifyUser(createUserProperties(userInfo));
}