import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IOrganisation } from "userandauth/interfacesUser";
import { getAllOrganisation } from "userandauth/servicesUser";

import { userKeys } from "./userKeys";

function useGetOrganisations({ enabled = true } = {}) {
  return useQuery<IOrganisation[], AxiosError>(
    {
      queryKey: userKeys.allOrganisation(),
      queryFn: () => getAllOrganisation(),
      staleTime: Infinity,
      retry: 0,
      enabled,
    }
  );
}

export { useGetOrganisations };
