import * as React from "react";
import { Tooltip } from "@mui/material";
import clsx from "clsx";
import Icon from "common/components/icon/Icon";
import { format } from "date-fns";
import { RealtimeFailBoardEventType } from "gql/generated";
import { Trans, useTranslation } from "react-i18next";
import { Event } from "./useRealTimeQuery";

export function EventStatus({
  event,
  columnType,
}: {
  event: Event;
  columnType: ColumnType;
}) {
  const { t, i18n } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  switch (event.type) {
    case RealtimeFailBoardEventType.Online: {
      return (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.online")}
        </div>
      );
    }

    case RealtimeFailBoardEventType.MaintenanceExit: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-on-surface-high">
            {t("eventType.maintenanceExit")}
          </div>

          <div className="text-body2 leading-4 text-on-surface-high mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.ActiveCommand: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-success">
            {t("eventType.activeCommand")}
          </div>

          <div className="text-body2 leading-4 text-success mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.DeactiveCommand: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-error">
            {t("eventType.deactiveCommand")}
          </div>

          <div className="text-body2 leading-4 text-error mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.FullTestPass: {
      return (
        <div className="text-subtitle2 leading-5 text-success">
          {event.description}
        </div>
      );
    }
    case RealtimeFailBoardEventType.TestPass:
    case RealtimeFailBoardEventType.TestFail: {
      return (
        <div className="text-body2 leading-5 text-on-surface-high">
          {event.description}
        </div>
      );
    }

    case RealtimeFailBoardEventType.Open:
    case RealtimeFailBoardEventType.ArriveBy:
    case RealtimeFailBoardEventType.OpenRefill:
    case RealtimeFailBoardEventType.OpenService:
    case RealtimeFailBoardEventType.OpenTank: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-primary-500">
            {t("eventType.arriveBy")}
          </div>

          <div className="text-body2 leading-4 text-primary-500 mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.Information: {
      return !event.description ? (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.information")}
        </div>
      ) : (
        <div className="text-body2 leading-5 text-on-surface-high">
          {event.description}
        </div>
      );
    }

    case RealtimeFailBoardEventType.Alarm: {
      let description =
        i18n.language === "th"
          ? event.errorDetail?.descriptionTh
          : event.errorDetail?.descriptionEn;
      if (!description) {
        description = event.errorDetail?.raw;
      }

      const lastRefillInfoExist =
        event.slotDetail?.lastRefillBy || event.slotDetail?.lastRefillTimestamp;

      return (
        <>
          <div className="text-subtitle2 leading-5 text-error">
            {t("eventType.alarm")} {`(${event.errorCode})`}{" "}
            <span className="text-body2">{description} </span>{" "}
            <RecurringCountFlame
              count={event.recurring}
              columnType={columnType}
            />
          </div>

          {event.slotDetail?.lastRemain ||
          event.slotDetail?.lastRemain === 0 ? (
            <div className="text-caption text-on-surface-disabled leading-4">
              <Trans
                i18nKey="rtFail.slotRemaining"
                ns="maintenance"
                values={{
                  remaining: "" + event.slotDetail.lastRemain.toFixed(2) + " g",
                }}
                components={{
                  bold: <span className="text-caption text-on-surface-high" />,
                }}
              />
            </div>
          ) : null}

          {event.slotDetail?.usageSinceLastRefill ||
          event.slotDetail?.usageSinceLastRefill === 0 ? (
            <div className="text-caption text-on-surface-disabled leading-4">
              <Trans
                i18nKey="rtFail.slotUsage"
                ns="maintenance"
                values={{
                  usage:
                    "" +
                    event.slotDetail.usageSinceLastRefill.toFixed(2) +
                    " g",
                }}
                components={{
                  bold: <span className="text-caption text-on-surface-high" />,
                }}
              />
            </div>
          ) : null}

          {lastRefillInfoExist ? (
            <div className="text-caption text-on-surface-disabled leading-4">
              {t("lastRefill")}

              {event.slotDetail?.lastRefillBy ? (
                <>
                  {" "}
                  {t("by")}{" "}
                  <span className="text-caption text-on-surface-high">
                    {event.slotDetail.lastRefillBy}
                  </span>
                </>
              ) : null}

              {event.slotDetail?.lastRefillTimestamp ? (
                <>
                  {" "}
                  {t("on")}{" "}
                  <span className="text-caption text-on-surface-high">
                    {format(
                      new Date(event.slotDetail?.lastRefillTimestamp),
                      "dd/MM/yyyy"
                    )}
                  </span>
                </>
              ) : null}
            </div>
          ) : null}
        </>
      );
    }
    default: {
      return (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {String(event.type)}
        </div>
      );
    }
  }
}

function getColorForSoftwareHardwareFail({ count }: { count: number }) {
  if (count === 1) {
    return "text-black-overlay-16";
  } else if (count <= 4) {
    return "text-warning";
  } else if (count <= 7) {
    return "text-error";
  } else {
    return "text-[rgba(188,103,208,1)]";
  }
}
function getColorForInventoryFail({ count }: { count: number }) {
  if (count <= 2) {
    return "text-black-overlay-16";
  } else if (count <= 9) {
    return "text-warning";
  } else if (count <= 15) {
    return "text-error";
  } else {
    return "text-[rgba(188,103,208,1)]";
  }
}

export type ColumnType = "inventory" | "software-hardware-fail";

function RecurringCountFlame({
  count,
  columnType,
}: {
  count: number;
  columnType: ColumnType;
}) {
  if (!count) return;

  const colorClassName =
    columnType === "inventory"
      ? getColorForInventoryFail({ count })
      : getColorForSoftwareHardwareFail({ count });

  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  return (
    <Tooltip
      placement="top"
      arrow={true}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: "rgba(0,0,0,0.6)",
          },
        },
        arrow: {
          sx: {
            color: "rgba(0,0,0,0.6)",
          },
        },
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
      title={
        <div className="font-kanit text-overline  leading-4 tracking-[0.4px] text-on-primary-high max-w-48 text-pretty">
          {columnType === "inventory"
            ? t("recurringCount.inventoryErrorTooltip", {
                count: count,
              })
            : t("recurringCount.softwareHardwareErrorTooltip", {
                count: count,
              })}
        </div>
      }
    >
      <div
        className={clsx(
          "inline-flex items-center gap-0.5 leading-5 top-[3px] relative",
          colorClassName
        )}
      >
        <Icon name="Flame2" className={colorClassName} />
        <div className="leading-5">
          <span className="text-subtitle2">{count}</span>
        </div>
      </div>
    </Tooltip>
  );
}
