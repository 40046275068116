import * as React from "react";
import SideNavButton from "common/components/sidenavbutton/SideNavButton";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useCanAccessContractFeature } from "./hooks/useCanAccessContractFeature";
import { contractRoutes } from "./routes";

export function ContractSideNav() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isPending, allowed } = useCanAccessContractFeature({useErrorBoundary: false});
 
  if(isPending || !allowed) {
    return null;
  }

  return (
    <SideNavButton
      to={contractRoutes.index()}
      translationKey={t("label_contract")}
      icon="Clipboard"
      active={pathname.includes(contractRoutes.index())}
    />
  );
}
