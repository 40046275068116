import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { httpClient } from "common/services/transportService";
import { queryClient } from "index";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";

export const ACTION = {
  DELETE: "delete",
  READ: "read",
  WRITE: "write",
  SELF_TEST: "self-test",
  MOVE: "move",
  MANAGE: "manage",
};
export const RESOURCE_TYPE = {
  MACHINE: "machine",
  REFILL_ZONE: "refillZone",
  ADMIN_USER: "adminUser",
  ADMIN_USER_ROLE: "adminUserRole",
  LOCATION: "location",
  CONTRACT: "contract",
  PLAN: "plan",
};

export type Action = keyof typeof ACTION;
export type ResourceType = keyof typeof RESOURCE_TYPE;
export type Permission = {
  userId: string;
  action: Action;
  resourceType: ResourceType;
};

const permissionQueryKey = (permission: Permission) => [
  { ...permission, scope: "permission" },
];
type PermissionQueryKey = ReturnType<typeof permissionQueryKey>;

export function useGetOwnPermission(
  permission: Pick<Permission, "action" | "resourceType">,
  {
    useErrorBoundary = false,
  }: {
    useErrorBoundary?: boolean;
  } = {}
) {
  const currentUserQuery = useGetCurrentUser();
  const { allowed, isPending, ...result } = useGetPermissionForUser(
    {
      userId: currentUserQuery.data.userId,
      ...permission,
    },
    {
      useErrorBoundary,
    }
  );
  if (allowed !== undefined && !allowed && useErrorBoundary) {
    throw new Error(
      `User ${currentUserQuery.data.userId} does not have ${permission.action} ${permission.resourceType}`
    );
  }
  return { allowed, isPending, ...result };
}

export function useGetPermissionForUser(
  permission: Permission,
  { useErrorBoundary }: { useErrorBoundary?: boolean } = {}
) {
  const query = useQuery({
    queryKey: permissionQueryKey(permission),
    queryFn: getPermissionQueryFn,
    throwOnError: useErrorBoundary,
  });
  const allowed = !useErrorBoundary && query.isError ? false : query.data;
  return { ...query, allowed};
}

export async function getPermission({
  action,
  resourceType,
  userId,
}: {
  action: Action;
  resourceType: ResourceType;
  userId: string;
}) {
  const response = await httpClient.post("/auth/query-permissions", [
    {
      userId,
      action: ACTION[action],
      resourceType: RESOURCE_TYPE[resourceType],
    },
  ]);
  if (Array.isArray(response.data) && response.data.length > 0) {
    return true;
  }
  return false;
}

export function setPermissionCache({
  userId,
  action,
  resourceType,
  allowed,
}: {
  userId: string;
  action: Action;
  resourceType: ResourceType;
  allowed: boolean;
}) {
  queryClient.setQueryData(
    permissionQueryKey({ userId, action, resourceType }),
    allowed
  );
}

export async function getPermissionQueryFn({
  queryKey: [{ action, resourceType, userId }],
}: QueryFunctionContext<PermissionQueryKey>): Promise<boolean> {
  return await getPermission({ action, resourceType, userId });
}
