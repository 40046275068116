import * as React from "react";
import { Slot } from "../types";

export function RemainingWithSlotsCell({
  slots,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
}: {
  slots: Slot[];
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}) {
  if (!slots || slots.length === 0) {
    return <div className="text-body2">{"-"}</div>;
  }

  if (slots.length === 1) {
    return (
      <div className="text-body2">
        {slots[0].lastRemain?.toLocaleString(undefined, {
          minimumFractionDigits,
          maximumFractionDigits,
        }) ?? "-"}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[19px,10px,1fr] text-body2">
      {slots.map((slot) => {
        return (
          <React.Fragment key={slot.slot}>
            <div className="overflow-hidden whitespace-nowrap text-ellipsis">
              {slot.slot}
            </div>
            <div>=</div>
            <div className="overflow-hidden whitespace-nowrap text-ellipsis">
              {slot.lastRemain?.toLocaleString(undefined, {
                minimumFractionDigits,
                maximumFractionDigits,
              })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
