import * as React from "react";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import * as styles from "./MaintenanceTable.module.scss";
import { NoData } from "./NoData";

interface MaintenanceTableProps<T, S extends string> {
  data: Array<T> | undefined;
  isLoading?: boolean;
  getKey: (dataPoint: T) => string;
  isPaginating?: boolean;
  containerStyles: React.CSSProperties;
  columns: Array<{
    id: S;
    commonStyle: React.CSSProperties;
    headerStyle?: React.CSSProperties;
    rowStyle: React.CSSProperties;
    loadingRowStyle?: React.CSSProperties;
    headerCell: (columnId: S) => JSX.Element;
    cell: (dataPoint: T) => JSX.Element;
  }>;
}

export function MaintenanceTable<T, S extends string>({
  isLoading = false,
  isPaginating = false,
  data,
  getKey,
  columns,
  containerStyles,
}: MaintenanceTableProps<T, S>): JSX.Element {
  const { t } = useTranslation("maintenance");

  const ref = React.useRef();
  const headerRow = React.useRef();

  React.useEffect(() => {
    function handleScroll() {
      if (ref.current && headerRow.current) {
        const headerRowElement = headerRow.current as HTMLElement;
        const refElement = ref.current as HTMLElement;

        const rect = refElement.getBoundingClientRect();
        const header = 60;
        const relativeTop = rect.top - header;

        if (relativeTop < 0) {
          headerRowElement.style.transform = `translateY(${-relativeTop}px)`;
        } else {
          headerRowElement.style.transform = `translateY(0px)`;
        }
      }
    }

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <div className={styles.TableContainer} style={containerStyles}>
      <div className={styles.MinWidthContainer} ref={ref}>
        <div
          className={clsx(styles.HeaderRow, "will-change-transform")}
          ref={headerRow}
        >
          {columns.map((column) => (
            <div
              key={column.id}
              style={{ ...column.commonStyle, ...(column.headerStyle ?? {}) }}
            >
              {column.headerCell(column.id)}
            </div>
          ))}
        </div>

        {isLoading ? <LoadingRows columns={columns} numberOfRows={20} /> : null}

        {data?.map((element) => (
          <div
            key={getKey(element)}
            className={[styles.FadeIn, styles.Row].join(" ")}
          >
            {columns.map((column) => (
              <div
                key={column.id}
                style={{ ...column.commonStyle, ...(column.rowStyle ?? {}) }}
              >
                {column.cell(element)}
              </div>
            ))}
          </div>
        ))}

        {isPaginating ? (
          <LoadingRows columns={columns} numberOfRows={10} />
        ) : null}
      </div>

      {!isLoading && data?.length === 0 ? (
        <NoData text={t("noErrorAndFailNow")} />
      ) : null}
    </div>
  );
}

function LoadingRows<T, S extends string>({
  columns,
  numberOfRows,
}: {
  columns: MaintenanceTableProps<T, S>["columns"];
  numberOfRows: number;
}) {
  return (
    <>
      {new Array(numberOfRows).fill(null).map((_, index) => (
        <div key={index} className={styles.Row}>
          {columns.map((column) => (
            <div
              key={column.id}
              className={styles.LoadingCell}
              style={{
                ...column.commonStyle,
                ...(column.loadingRowStyle ?? {}),
                scrollSnapAlign: "start",
              }}
            >
              <div className={styles.SkeletonContainer}>
                <Skeleton width={"100%"} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
