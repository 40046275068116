import * as React from "react";
import { RealtimeFailBoardEventType } from "gql/generated";

export function getStatusIndicator(
  eventType: RealtimeFailBoardEventType,
) {
  return STATUS_TO_INDICATOR[eventType] ?? defaultIndicator;
}

const defaultIndicator = (
  <div className="size-[10px] rounded-full bg-on-surface-medium" />
);

const purpleIndicator = (
  <div className="relative flex items-center justify-center isolate">
    <div className="size-[10px] rounded-full bg-primary-500"></div>
    <div className="size-4 absolute -z-10 rounded-full bg-[rgba(52,71,173,0.24)]"></div>
  </div>
);

const successIndicator = (
  <div className="size-[10px] rounded-full bg-success" />
);
const successIndicatorWithMediumBorder = (
  <div className="relative flex items-center justify-center isolate">
    <div className="size-[10px] rounded-full bg-success"></div>
    <div className="size-[13px] absolute -z-10 rounded-full bg-secondary-200"></div>
  </div>
);
const successIndicatorWithLargeBorder = (
  <div className="relative flex items-center justify-center isolate">
    <div className="size-[10px] rounded-full bg-success"></div>
    <div className="size-4 absolute -z-10 rounded-full bg-secondary-200"></div>
  </div>
);

const errorIndicator = <div className="size-[10px] rounded-full bg-error" />;
const warningIndicator = (
  <div className="size-[10px] rounded-full bg-warning" />
);

const STATUS_TO_INDICATOR: Record<RealtimeFailBoardEventType, JSX.Element> = {
  [RealtimeFailBoardEventType.Online]: successIndicator,
  [RealtimeFailBoardEventType.Alarm]: errorIndicator,
  [RealtimeFailBoardEventType.ActiveCommand]: successIndicator,
  [RealtimeFailBoardEventType.DeactiveCommand]: errorIndicator,
  [RealtimeFailBoardEventType.ArriveBy]: purpleIndicator,
  [RealtimeFailBoardEventType.Open]: purpleIndicator,
  [RealtimeFailBoardEventType.OpenRefill]: purpleIndicator,
  [RealtimeFailBoardEventType.OpenService]: purpleIndicator,
  [RealtimeFailBoardEventType.OpenTank]: purpleIndicator,
  [RealtimeFailBoardEventType.Information]: defaultIndicator,
  [RealtimeFailBoardEventType.FullTestPass]: successIndicatorWithLargeBorder,
  [RealtimeFailBoardEventType.TestFail]: warningIndicator,
  [RealtimeFailBoardEventType.TestPass]: successIndicatorWithMediumBorder,
  [RealtimeFailBoardEventType.MaintenanceExit]: successIndicator,
};
