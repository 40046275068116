import * as React from "react";
import { clsx } from "clsx";
import {
  useGetRefillZonesByOrgIdQuery,
  useGetServiceZonesByOrgIdQuery,
} from "gql/generated";
import { useTranslation } from "react-i18next";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { useSelectedZones } from "./SelectedZoneProvider";

const STALE_TIME = 5 * 60 * 1000;

export function SelectedZoneChips() {
  const { t } = useTranslation("maintenance");

  const {
    data: { orgId: organisationId },
  } = useGetCurrentUser();
  const serviceZoneresult = useGetServiceZonesByOrgIdQuery(
    {
      organisationId,
    },
    {
      throwOnError: false,
      staleTime: STALE_TIME,
    }
  );
  const refillZoneResult = useGetRefillZonesByOrgIdQuery(
    {
      organisationId,
    },
    {
      throwOnError: false,
      staleTime: STALE_TIME,
    }
  );

  const isLoading = serviceZoneresult.isLoading || refillZoneResult.isLoading;

  const { deselectZone, selectedZones, clearSelection } = useSelectedZones();

  const selectedZoneWithFriendlyIdLabel = selectedZones?.map((zone) => {
    const zones =
      zone.type === "REFILL"
        ? refillZoneResult.data?.refillZones
        : serviceZoneresult.data?.serviceZones;

    const label = zones?.find((sz) => sz.id === zone.id)?.friendlyId ?? zone.id;

    return {
      ...zone,
      label: label ?? zone.id,
    };
  });

  const isChipsShown = !isLoading && selectedZones.length !== 0;

  return (
    <div
      className={clsx(
        "grid overflow-hidden transition-all ease-in-out duration-300",
        isChipsShown ? "[grid-template-rows:1fr]" : "[grid-template-rows:0fr]"
      )}
    >
      <div
        className={clsx(
          "min-h-0 transition-all w-full min-w-0",
          isChipsShown ? "visible" : "invisible"
        )}
      >
        <div className="flex gap-4 justify-between items-center">
          <div
            className={clsx(
              "flex gap-2 min-w-0 overflow-auto items-center overscroll-contain flex-grow hide-scroll-bar"
            )}
          >
            {isChipsShown
              ? selectedZoneWithFriendlyIdLabel?.map((zone) => (
                  <button
                    key={zone.id}
                    className="cursor-pointer text-black animate-fade-in-move-up font-kanit font-medium py-[5px] text-sm leading-[18px] border-none outline-none pl-[9px] pr-[7px] shrink-0 flex items-center gap-1 bg-[rgba(33,33,33,0.08)] rounded-full hover:bg-[rgba(33,33,33,0.18)] transition-all"
                    onClick={() => deselectZone(zone.id)}
                  >
                    <span>
                      {zone.type === "REFILL"
                        ? t("refillZone")
                        : t("serviceZone")}
                      {" : "}
                    </span>
                    <span>{zone.label}</span>
                    <span className="flex">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.7141 1.99615C5.92854 1.99615 2.06027 5.86442 2.06027 10.65C2.06027 15.4356 5.92854 19.3038 10.7141 19.3038C15.4997 19.3038 19.368 15.4356 19.368 10.65C19.368 5.86442 15.4997 1.99615 10.7141 1.99615ZM15.041 13.7567L13.8208 14.9769L10.7141 11.8702L7.60738 14.9769L6.38719 13.7567L9.49392 10.65L6.38719 7.54327L7.60738 6.32307L10.7141 9.4298L13.8208 6.32307L15.041 7.54327L11.9343 10.65L15.041 13.7567Z"
                          fill="black"
                          fillOpacity="0.6"
                        />
                      </svg>
                    </span>
                  </button>
                ))
              : null}
          </div>

          <button
            className="border-none bg-none bg-transparent text-error hover:text-red-500 transition-all font-kanit text-base leading-5 hover:cursor-pointer whitespace-nowrap h-[35px] flex items-center justify-center"
            onClick={() => clearSelection()}
          >
            {t("clearAll")}
          </button>
        </div>
      </div>
    </div>
  );
}
