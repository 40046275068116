import * as React from "react";

interface IAlertIconProps {
  color?: string;
  className?: string;
}

export default function AlertIcon({ color = "currentcolor", className }: IAlertIconProps) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.6802 10.9121L10.0747 1.20294C9.1591 -0.400979 6.84379 -0.400979 5.9315 1.20294L0.322735 10.9121C-0.592847 12.516 0.546691 14.5085 2.39432 14.5085H13.5888C15.4364 14.5085 16.5957 12.4962 15.6802 10.9121ZM7.9998 12.3546C7.51236 12.3546 7.10727 11.9495 7.10727 11.4621C7.10727 10.9746 7.51236 10.5695 7.9998 10.5695C8.48723 10.5695 8.89233 10.9746 8.87256 11.4851C8.89562 11.9495 8.46747 12.3546 7.9998 12.3546ZM8.81328 6.58446C8.77376 7.27608 8.73095 7.96442 8.69142 8.65604C8.67166 8.88 8.67166 9.08419 8.67166 9.30486C8.6519 9.67043 8.36537 9.95367 7.9998 9.95367C7.63422 9.95367 7.35099 9.69019 7.32793 9.32462C7.26865 8.24765 7.20607 7.19045 7.14679 6.11349C7.12703 5.83025 7.10727 5.54372 7.08421 5.26048C7.08421 4.79281 7.34769 4.40748 7.77584 4.28562C8.20399 4.18352 8.62885 4.38772 8.81328 4.79281C8.87586 4.93443 8.89562 5.07605 8.89562 5.24072C8.87586 5.69193 8.83304 6.13984 8.81328 6.58446Z"
        fill={color}
      />
    </svg>
  );
}
