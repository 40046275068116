import React from "react";
import {
  GoogleLogin,
  GoogleLoginProps,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import config from "common/config";



function WrappedGoogleLogin(props: GoogleLoginProps) {
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <GoogleLogin {...props} />
    </GoogleOAuthProvider>
  );
}

export default WrappedGoogleLogin;
