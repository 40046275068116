import React from "react";

import * as styles from "./ToggleSwitch.module.scss";

interface IToggleSwitch {
  isOn: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

function ToggleSwitch({
  isOn,
  onClick,
  disabled = false,
  className,
}: IToggleSwitch): JSX.Element {
  return (
    <div
      className={[
        className,
        styles.ToggleSwitch,
        disabled ? styles.ToggleSwitchDisabled : "",
      ].join(" ")}
      onClick={disabled ? () => {} : onClick}
    >
      <div
        className={[
          styles.Bar,
          isOn && disabled ? styles.BarOnDisabled : "",
          isOn && !disabled ? styles.BarOn : "",
          !isOn && disabled ? styles.BarOffDisabled : "",
          !isOn && !disabled ? styles.BarOff : "",
        ].join(" ")}
      >
        <div
          className={[
            styles.Circle,
            isOn && disabled ? styles.CircleOnDisabled : "",
            isOn && !disabled ? styles.CircleOn : "",
            !isOn && disabled ? styles.CircleOffDisabled : "",
            !isOn && !disabled ? styles.CircleOff : "",
          ].join(" ")}
        ></div>
      </div>
    </div>
  );
}

export { ToggleSwitch };
