import * as React from "react";
import { Popover } from "@mui/material";
import Button from "components/Button";
import { format, isSameDay } from "date-fns";
import { DayPicker as ReactDayPicker, PropsSingle } from "react-day-picker";
import { th, enUS } from "react-day-picker/locale";
import { useTranslation } from "react-i18next";
import "react-day-picker/src/style.css";
import "./style.css";

export function DayPicker({
  selected,
  onSelect,
  ButtonComponent,
}: Pick<PropsSingle, "selected"> & {
  onSelect: (selected: PropsSingle["selected"]) => void;
} & {
  ButtonComponent?: React.ComponentType<
    React.ButtonHTMLAttributes<HTMLButtonElement> & { isOpen: boolean }
  >;
}) {
  const [_selected, _setSelected] =
    React.useState<PropsSingle["selected"]>(selected);

  function onClose() {
    if (!_selected) {
      _setSelected(selected);
    } else {
      onSelect(_selected);
    }
    setAnchorEl(null);
  }

  function handleOpen(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  const { i18n, t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const text = (
    <span className="text-on-surface-medium">
      {!isOpen || !_selected
        ? isSameDay(selected, new Date())
          ? t("label_today")
          : format(selected, "dd/MM/yyyy")
        : format(_selected, "dd/MM/yyyy")}
    </span>
  );

  return (
    <div className="h-full">
      {ButtonComponent ? (
        <ButtonComponent isOpen={isOpen} onClick={handleOpen}>
          {text}
        </ButtonComponent>
      ) : (
        <Button
          className="w-full !text-on-surface-medium"
          onClick={handleOpen}
          type="secondary"
          variant="outlined"
          size="large"
        >
          {text}
        </Button>
      )}

      <Popover
        onClose={onClose}
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="p-5">
          <ReactDayPicker
            classNames={{
              selected: "bg-primary-500 !text-white rounded",
            }}
            locale={i18n.language === "th" ? th : enUS}
            mode="single"
            defaultMonth={selected}
            onSelect={_setSelected}
            selected={_selected}
            styles={{
              day: {
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "Kanit",
              },

              selected: {
                backgroundColor: "rgb(75, 77, 155)",
                color: "white",
              },
              weekday: {
                opacity: 0.5,
                fontWeight: 400,
                fontSize: "smaller",
                fontFamily: "Kanit",
              },
              month_caption: {
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "Kanit",
              },
            }}
            disabled={{
              after: new Date(),
            }}
          />
        </div>
      </Popover>
    </div>
  );
}
