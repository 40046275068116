import React from "react";
import { Tooltip } from "@mui/material";
import { MachineInventoryStatus } from "gql/generated";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { Slot } from "../types";

export function ErrorDetailWithSlotsCell({ slots }: { slots: Slot[] }) {
  if (!slots || slots.length === 0) {
    return null;
  }

  if (slots.length === 1) {
    return (
      <div className="text-subtitle2 text-error">
        <SlotValue slot={slots[0]} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[19px,10px,1fr] text-body2">
      {slots.map((slot) => {
        return (
          <React.Fragment key={slot.slot}>
            <div className="overflow-hidden whitespace-nowrap text-ellipsis">
              {slot.slot}
            </div>
            <div>=</div>
            <div className="text-error text-subtitle2 overflow-hidden whitespace-nowrap text-ellipsis">
              <SlotValue slot={slot} />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

function SlotValue({ slot }: { slot: Slot }) {
  const { errorCode, status } = slot;

  if (!slot.errorCode && !slot.status) return null;
  if (!errorCode && status)
    return TRANSLATION_MAP[status] ?? String(status).toUpperCase();
  if (errorCode && !status) return <ErrorCode slot={slot} />;

  switch (status) {
    case MachineInventoryStatus.Abnormal:
    case MachineInventoryStatus.Off:
    case MachineInventoryStatus.Low:
    case MachineInventoryStatus.DisableLow:
    case MachineInventoryStatus.Disable:
      return TRANSLATION_MAP[status] ?? String(status).toUpperCase();

    case MachineInventoryStatus.Normal:
      return <ErrorCode slot={slot} />;
  }
}

const TRANSLATION_MAP: Record<MachineInventoryStatus, string> = {
  [MachineInventoryStatus.Abnormal]: "ABNORMAL",
  [MachineInventoryStatus.Disable]: "DISABLE",
  [MachineInventoryStatus.DisableLow]: "D-LOW",
  [MachineInventoryStatus.Low]: "LOW",
  [MachineInventoryStatus.Normal]: "NORMAL",
  [MachineInventoryStatus.Off]: "OFF",
};

export function ErrorCode({ slot }: { slot: Slot }) {
  const { i18n } = useTranslation();

  if (slot.errorCode === null) return null;

  const description =
    i18n.language === "th"
      ? slot.errorDetail?.descriptionTh
      : slot.errorDetail?.descriptionEn;

  return (
    <Tooltip
      placement="top-start"
      arrow={true}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-12, 0],
              },
            },
          ],
        },
      }}
      title={
        description ? (
          <div className="font-kanit text-overline tracking-[0.4px] text-primary-50">
            {capitalize(description)}
          </div>
        ) : null
      }
    >
      <div>{slot.errorCode}</div>
    </Tooltip>
  );
}
