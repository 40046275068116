import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { clsx } from "clsx";
import { useAmplitudeFeatureFlag } from "common/hooks/useAmplitudeFeatureFlag";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { usePatchUserDetails } from "userandauth/hooks/usePatchUserDetails";
import analytics from "utils/analytics";

import { ToggleSwitch } from "../ToggleSwitch";
import { OrgSwitcherModal } from "./OrgSwitcherModal";
import * as styles from "./TopNav.module.scss";
import Icon from "../icon/Icon";

function TopNav(): JSX.Element {
  const { enable: canAccessNotificationFeature } = useAmplitudeFeatureFlag("notification");

  const { t } = useTranslation();
  const [openOrgSwitcher, setOpenOrgSwitcher] = React.useState(false);
  const { data: userInfo } = useGetCurrentUser();
  const { mutate: patchUser } = usePatchUserDetails();

  function onChangeUserDetails(value: string | boolean, key: string) {
    patchUser(
      { userId: userInfo.userId, newUserData: { [key]: value } },
      { onSuccess: () => location.reload() }
    );
  }

  return (
    <div className={styles.TopNav}>
      {canAccessNotificationFeature && (
        <Link
          to="/notification"
          className="h-[60px] px-3 flex items-center hover:bg-gray-100 cursor-pointer"
        >
          <Icon name="Notification" className="w-6 h-6 text-primary" />
        </Link>
      )}
      {userInfo.canToggleViewAllMachines && (
        <div className="relative flex items-center justify-center sm:min-w-[150px] h-[60px] px-1 sm:px-3 gap-1">
          <ToggleSwitch
            className="w-9"
            isOn={userInfo.canViewAllMachines}
            onClick={() =>
              onChangeUserDetails(
                !userInfo.canViewAllMachines,
                "canViewAllMachines"
              )
            }
          />
          <div className={styles.toggleButtonText}>
            <p className="text-body2 sm:text-subtitle1 whitespace-nowrap">
              {t("label_all_machine")}
            </p>
          </div>
        </div>
      )}

      <div
        className={clsx(
          "relative flex items-center h-[60px] px-2.5 gap-2.5",
          userInfo.canImpersonate && "hover:bg-background cursor-pointer"
        )}
        onClick={() => {
          if (userInfo.canImpersonate) {
            setOpenOrgSwitcher(true);
            analytics.track({
              name: "Organisation Switcher Button Clicked ",
              properties: {},
            });
          }
        }}
      >
        <div className="h-10 w-10 rounded-full bg-background">
          <Avatar
            src={userInfo.orgAvatarUrl}
            className="w-10 h-10 bg-overlay-surface-08 flex items-center justify-center"
          >
            <Icon
              name="Building"
              className="w-6 h-6"
              color="onSurfaceDisabled"
            />
          </Avatar>
        </div>

        <div className="flex gap-3 items-center justify-between pr-2.5 px-2 py-1 rounded-lg">
          <p className="text-body3 sm:text-body1 line-clamp-2">
            {userInfo.orgName}
          </p>
          {userInfo.canImpersonate && (
            <Icon className="-rotate-90" name="Chevron" color="onSurfaceHigh" />
          )}
        </div>
      </div>
      {userInfo.canImpersonate && (
        <OrgSwitcherModal
          key={String(openOrgSwitcher)}
          open={openOrgSwitcher}
          onClose={() => {
            setOpenOrgSwitcher(false);
          }}
        />
      )}

      <div className={styles.user}>
        <div className={styles.iconContainer}>
          <div className={styles.avatar}>
            <Avatar className="w-10 h-10" src={userInfo.userAvatarUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
