import * as React from "react";
import { ErrorBoundary } from "@sentry/react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AaNoTransaction30MinsTable } from "./AaNoTransaction30MinsTable";
import { BillAndCoinTable } from "./BillAndCoinTable";
import { NoTransaction2HoursTable } from "./NoTransaction2HoursTable";
import { NoTransactionTodayTable } from "./NoTransactionTodayTable";
import { routes } from "../../../../routes";
import { useSelectedZones } from "../../../../SelectedZoneProvider";
import { Fallback } from "../../components/Fallback";

export function TransactionsTable() {
  const { serviceZoneIds, refillZoneIds } = useSelectedZones();
  const key = serviceZoneIds?.join("") + refillZoneIds?.join("");

  return (
    <Routes>
      <Route
        path="/aa-no-transaction-30-mins"
        element={
          <ErrorBoundary
            fallback={Fallback}
            key={"aANoTransaction30Mins" + key}
          >
            <AaNoTransaction30MinsTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="/no-transaction-2-hours"
        element={
          <ErrorBoundary fallback={Fallback} key={"noTransaction2Hours" + key}>
            <NoTransaction2HoursTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="/no-transaction-today"
        element={
          <ErrorBoundary fallback={Fallback} key={"noTransactionToday" + key}>
            <NoTransactionTodayTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="/bill-and-coin"
        element={
          <ErrorBoundary fallback={Fallback} key={"billAndCoin" + key}>
            <BillAndCoinTable />
          </ErrorBoundary>
        }
      />

      <Route
        path="*"
        element={
          <Navigate to={routes.transactions.aaNoTransaction30Mins} replace />
        }
      />
    </Routes>
  );
}
