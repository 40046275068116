import * as React from "react";
import { clsx } from "clsx";
import { RefillZoneDropDown } from "containers/maintenance/RefillZoneDropDown";
import { SelectedZoneChips } from "containers/maintenance/SelectedZoneChips";
import { SelectedZonesProvider } from "containers/maintenance/SelectedZoneProvider";
import { ServiceZoneDropDown } from "containers/maintenance/ServiceZoneDropDown";
import { Route, Routes, Navigate } from "react-router-dom";

import * as styles from "./ErrorBoard.module.scss";
import { ErrorBoardTabs } from "./ErrorBoardNav";
import { ErrorOverviews } from "./ErrorOverviews";
import { useErrorBoard } from "./hooks/useErrorBoard";
import { LastUpdatedIndicator } from "./LastUpdatedIndicator";
import { RefreshButton } from "./RefreshButton";
import { routes } from "../../routes";
import { ErrorBoardProvider } from "./providers/ErrorBoardProvider";
import { InventoryView } from "./views/InventoryView";
import { MajorTroubleView } from "./views/MajorTroubleView";
import { TransactionsView } from "./views/TransactionsView";

export function ErrorBoard({ className }: { className?: string }) {
  return (
    <SelectedZonesProvider>
      <ErrorBoardProvider>
        <ErrorBoardContents className={className} />
      </ErrorBoardProvider>
    </SelectedZonesProvider>
  );
}

function ErrorBoardContents({ className }: { className: string }) {
  const { paginate } = useErrorBoard();

  const intersectionRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          paginate();
        }
      },
      {
        rootMargin: "100px",
        threshold: 1,
      }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (observer) {
        if (intersectionRef.current) {
          observer.unobserve(intersectionRef.current);
        }
        observer.disconnect();
      }
    };
  }, [intersectionRef.current, paginate]);

  return (
    <div className={clsx(styles.ErrorBoardContainer, className)}>
      <div className="overflow-auto hide-scroll-bar -mx-4 px-4 md:-mx-10 md:px-10 flex">
        <div className="grow">
          <div className="flex justify-between gap-2.5 mt-2 md:mt-4">
            <div className="grid [grid-template-columns:210px_210px] gap-2 md:gap-4 grow">
              <ServiceZoneDropDown />
              <RefillZoneDropDown />
            </div>

            <div className="items-center gap-1 shrink-0 flex">
              <LastUpdatedIndicator />
              <RefreshButton />
            </div>
          </div>

          <div className="mt-1 md:mt-2">
            <SelectedZoneChips />
          </div>
        </div>
      </div>

      <div className="mt-1 md:mt-2">
        <ErrorOverviews />
      </div>

      <div className={clsx(styles.ErrorBoard, "mt-2 md:mt-4")}>
        <div>
          <ErrorBoardTabs />
        </div>

        <div className={styles.SubNavAndTableContainer}>
          <Routes>
            <Route path="/major-trouble/*" element={<MajorTroubleView />} />
            <Route path="/inventory/*" element={<InventoryView />} />
            <Route path="/transactions/*" element={<TransactionsView />} />
            <Route
              path="*"
              element={<Navigate to={routes.majorTrouble.root} replace />}
            />
          </Routes>
          <div ref={intersectionRef}></div>
        </div>
      </div>
    </div>
  );
}
