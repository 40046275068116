import * as React from "react";

interface IArrowPriorityLowProps {
  color?: string;
  className?: string;
}

function ArrowPriorityLow({ color = "currentcolor", className }: IArrowPriorityLowProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.4066 6.14463C12.9066 5.84463 13.5066 6.04463 13.8066 6.54463C14.1066 7.04463 13.9066 7.54463 13.4066 7.84463L8.40656 10.8446C8.10656 11.0446 7.70656 11.0446 7.40656 10.8446L2.40656 7.84463C1.90656 7.54463 1.80656 6.94463 2.10656 6.44463C2.30656 6.04463 2.90656 5.84463 3.40656 6.14463L7.90656 8.84463L12.4066 6.14463Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowPriorityLow;
