import * as React from "react";
import { Skeleton } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { format, isSameDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { RealBoardWithLastUpdated } from "./useRealTimeQuery";

export function LastUpdatedIndicator({
  realTimeFailResult,
  date,
}: {
  realTimeFailResult: UseQueryResult<RealBoardWithLastUpdated, unknown>;
  date: Date;
}) {
  const { t } = useTranslation("maintenance");

  let text: string;
  if (realTimeFailResult.data) {
    text = isSameDay(date, realTimeFailResult.data.lastUpdated)
      ? format(realTimeFailResult.data.lastUpdated, "MMM dd, yyyy HH:mm")
      : "-";
  }

  return (
    <div className="flex flex-col min-w-[140px] items-end">
      <p className="text-body3 text-on-surface-medium">{t("lastUpdated")}</p>
      <p className="text-subtitle1 text-on-surface-high">
        {realTimeFailResult.isError && !text ? <span>-</span> : null}

        {text && <span>{text}</span>}
        {realTimeFailResult.isLoading && <Skeleton width={130} />}
      </p>
    </div>
  );
}
