import * as React from "react";

interface Flame2Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export default function Flame2({
  color = "currentcolor",
  ...props
}: Flame2Props): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66699 9.33333C2.66699 8.16667 2.94477 7.12778 3.50033 6.21667C4.05588 5.30556 4.66699 4.53889 5.33366 3.91667C6.00033 3.29444 6.61144 2.81933 7.16699 2.49133L8.00033 2V4.2C8.00033 4.61111 8.13921 4.936 8.41699 5.17467C8.69477 5.41333 9.00588 5.53289 9.35032 5.53333C9.53921 5.53333 9.71988 5.49444 9.89233 5.41667C10.0648 5.33889 10.223 5.21111 10.367 5.03333L10.667 4.66667C11.467 5.13333 12.1114 5.78044 12.6003 6.608C13.0892 7.43555 13.3337 8.344 13.3337 9.33333C13.3337 10.3111 13.0948 11.2029 12.617 12.0087C12.1392 12.8144 11.5114 13.4502 10.7337 13.916C10.9225 13.6493 11.0699 13.3578 11.1757 13.0413C11.2814 12.7249 11.3341 12.3889 11.3337 12.0333C11.3337 11.5889 11.2503 11.1693 11.0837 10.7747C10.917 10.38 10.6781 10.0273 10.367 9.71667L8.00033 7.4L5.65033 9.71667C5.3281 10.0389 5.08366 10.3944 4.91699 10.7833C4.75033 11.1722 4.66699 11.5889 4.66699 12.0333C4.66699 12.3889 4.71988 12.7251 4.82566 13.042C4.93144 13.3589 5.07855 13.6504 5.26699 13.9167C4.48921 13.45 3.86144 12.8138 3.38366 12.008C2.90588 11.2022 2.66699 10.3107 2.66699 9.33333ZM8.00033 9.26667L9.41699 10.65C9.60588 10.8389 9.75032 11.05 9.85032 11.2833C9.95033 11.5167 10.0003 11.7667 10.0003 12.0333C10.0003 12.5778 9.80588 13.0418 9.41699 13.4253C9.0281 13.8089 8.55588 14.0004 8.00033 14C7.44477 13.9996 6.97255 13.808 6.58366 13.4253C6.19477 13.0427 6.00033 12.5787 6.00033 12.0333C6.00033 11.7778 6.05033 11.5307 6.15033 11.292C6.25033 11.0533 6.39477 10.8393 6.58366 10.65L8.00033 9.26667Z"
        fill={color}
      />
    </svg>
  );
}
