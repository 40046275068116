import React from "react";
import * as colors from "common/styles/colors.module.scss";
import { twMerge } from "tailwind-merge"

import icons from "./icons";

export type IconNames = keyof typeof icons
export interface IIconProps {
  /**
   * The name of the icon to render.
   * Must match one of the keys in the `icons` object.
   */
  name: IconNames;

  /**
   * @deprecated This prop is deprecated and will be removed in a future release.
   * Use CSS classes (e.g., Tailwind's `text-{color}` utilities) or inline styles
   * to determine the icon's color instead.
   */
  color?: string;

  /**
   * Additional class names to apply to the icon component.
   * Recommended for styling with Tailwind CSS or custom CSS.
   */
  className?: string;
}

export const iconsKeys = Object.keys(icons);

function Icon ({
  name,
  color,
  className,
  ...restProps
}: IIconProps &  React.SVGProps<SVGSVGElement>): JSX.Element {
  
  const IconComponent = icons[name];

  if (!IconComponent) {
    console.warn(`${name} is not a valid icon!`);
    return null;
  }

  return (
    <IconComponent
      className={twMerge("text-white", className)}
      color={color ? colors[color || "surface"]: undefined}
      {...restProps }
    />
  );
}

export default Icon;
