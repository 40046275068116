import * as React from "react";
import { Skeleton } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useErrorBoard } from "./hooks/useErrorBoard";

export function LastUpdatedIndicator() {
  const { t } = useTranslation("maintenance");
  const { state } = useErrorBoard();

  return (
    <div className="flex flex-col items-start min-w-[140px]">
      <p className="text-body3 text-on-surface-medium">{t("lastUpdated")}</p>
      <p className="text-subtitle1 text-on-surface-high">
        {state.status === "error" && <span>-</span>}
        {state.status !== "error" && state.data.lastUpdated && (
          <span>
            {moment(state.data.lastUpdated).format("MMM DD, YYYY HH:mm")}
          </span>
        )}
        {state.status !== "error" && !state.data.lastUpdated && (
          <Skeleton width={130} />
        )}
      </p>
    </div>
  );
}
