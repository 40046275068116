import React from "react";
import "utils/date";
import * as Sentry from "@sentry/react";
import "./utils/analytics";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "App";

import "common/i18n";

import config from "common/config";
import { AnalyticClickElementTracking } from "components/AnalyticClickElementTracking";
import { createRoot } from "react-dom/client";
import { ERROR_FAILED_TO_REFRESH_TOKEN } from "userandauth/utilsAuth";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT,
  tracesSampleRate: config.SENTRY_ENVIRONMENT === "production" ? 0.5 : 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [process.env.API_URL],
      networkCaptureBodies: true,
      maskAllText: false,
      maskAllInputs: false,
    }),
    new Sentry.Feedback({
      autoInject: false,
      showBranding: false,
      buttonLabel: "Report Problem",
      submitButtonLabel: "Send",
      formTitle: "Report Problem",
      messageLabel: "What happened",
      messagePlaceholder: "What’s the problem?\nWhat did you expect?",
    }),
  ],
  ignoreErrors: [ERROR_FAILED_TO_REFRESH_TOKEN],
});

const root = createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <AnalyticClickElementTracking />
    <ReactQueryDevtools initialIsOpen={false} position="bottom" />
  </QueryClientProvider>
);
